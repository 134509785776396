import cn from 'classnames'
import { useState } from 'react'
import css from './index.module.scss'
import { getYoutubeVideoEmbedUrl, getYoutubeVideoSize } from './utils'

export const VideoYoutube = ({
  id,
  width,
  height,
  className,
  eventElementId,
  eventElementGroup,
}: {
  id: string
  width?: number
  height?: number
  className?: string
  eventElementId?: string
  eventElementGroup?: string
}) => {
  const embedUrl = getYoutubeVideoEmbedUrl({ id })
  const size = getYoutubeVideoSize({ id, width, height })
  const [title] = useState(Math.random().toString())
  return (
    <div className={cn(css.videoOuterOuter, className)}>
      <div
        className={css.videoOuter}
        style={{
          paddingBottom: size.ratioPercentString,
        }}
      >
        <iframe
          className={css.video}
          width={width || size.width}
          height={height || size.height}
          src={embedUrl}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
