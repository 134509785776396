import { canGrouping } from '@svag/backend/src/services/user/can'
import { EdCards, type EdItem } from '../../../components/education/cards/EdCard'
import { Alert } from '../../../components/ui/Alert'
import { Segment, Segments } from '../../../components/ui/Segment'
import { useMe } from '../../../lib/ctx'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

export const GroupsPage = withPageWrapper({
  title: ({ t }) => t('groupsPage.title'),
  showLoaderOnFetching: false,
  useQuery: () => {
    return trpc.getEdItems.useQuery()
  },
  setProps: ({ queryResult }) => {
    return {
      edItems: queryResult.data.edItems,
    }
  },
  langs: ['ru', 'en', 'hy'],
})(({ edItems }) => {
  const { t } = useT('groupsPage')
  const me = useMe()
  const allGroups = edItems.filter((edItem) => edItem.type === 'group') as Array<Extract<EdItem, { type: 'group' }>>
  const groupsWhereMeActiveStudent = allGroups.filter((edItem) => !!edItem.isMeActiveStudent)
  const groupsWhereMeTeacher = allGroups.filter((edItem) => !!edItem.isMeTeacher)
  const restGroups = allGroups.filter((edItem) => !edItem.isMeTeacher && !edItem.isMeActiveStudent)
  return (
    <Segment title={t('title')}>
      <Segments>
        {canGrouping(me) && (
          <Segment size="M" title={t('teacherGroups.title')}>
            {groupsWhereMeTeacher.length ? (
              <EdCards edItems={groupsWhereMeTeacher} />
            ) : (
              <Alert color="brown">{t('teacherGroups.noGroups')}</Alert>
            )}
          </Segment>
        )}
        <Segment size="M" title={t('myGroups.title')}>
          {groupsWhereMeActiveStudent.length ? (
            <EdCards edItems={groupsWhereMeActiveStudent} />
          ) : (
            <Alert color="brown">{t('myGroups.noGroups')}</Alert>
          )}
        </Segment>
        <Segment size="M" title={t('otherGroups.title')}>
          {restGroups.length ? (
            <EdCards edItems={restGroups} />
          ) : (
            <Alert color="brown">{t('otherGroups.noGroups')}</Alert>
          )}
        </Segment>
      </Segments>
    </Segment>
  )
})
