import { EdCardsWithFilter } from '../../../components/education/cards/EdCard'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

export const EducationPage = withPageWrapper({
  title: ({ t }) => t('educationPage.title'),
  showLoaderOnFetching: false,
  useQuery: () => {
    return trpc.getEdItems.useQuery()
  },
  setProps: ({ queryResult }) => {
    return {
      edItems: queryResult.data.edItems,
    }
  },
  langs: ['ru', 'en', 'hy'],
})(({ edItems }) => {
  const { t } = useT('educationPage')

  return (
    <Segment title={t('title')}>
      <EdCardsWithFilter edItems={edItems} />
    </Segment>
  )
})
