import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { plural } from '@svag/shared/src/plural'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { CurrencySwitchPrice } from '../../../../../../components/other/CurrencySwitch'
import { useCurrency } from '../../../../../../lib/currency'
import { useT } from '../../../../../../lib/i18n'
import { trackClick } from '../../../../../../lib/mixpanel'
import { getGuideLessonRoute } from '../../../../../../lib/routes'
import { getHoursFromDuration } from '../../../../../../utils/getHoursFromDuration'
import css from './index.module.scss'

export const EdDevPromoPageProgram = ({
  guideLessons,
  guide,
  disableLinks = false,
}: {
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
  guideLessons: TrpcRouterOutput['getStudentGuideFull']['lessons']
  disableLinks?: boolean
}) => {
  const { t: tg } = useT('general')
  const { t } = useT('edDevPromoPageProgram')
  const { toMoneyWithCurrency } = useCurrency()
  const plansWithLessons = guide.plans
    .map((plan) => {
      const lessons = guideLessons.filter((lesson) => {
        if (plan.name === 'free') {
          return lesson.plans.includes('free')
        } else if (plan.name === 'basic') {
          return lesson.plans.includes('basic') && !lesson.plans.includes('free')
        } else if (plan.name === 'pro') {
          return lesson.plans.includes('pro') && !lesson.plans.includes('free') && !lesson.plans.includes('basic')
        }
        return false
      })
      return {
        ...plan,
        lessons,
      }
    })
    .filter((pwl) => pwl.lessons.length)
  return (
    <div
      className={cn({ [css.program]: true, [css.disableLinks]: disableLinks })}
      id="program"
      onClick={(e) => {
        const target = e.target as HTMLElement
        if (target.tagName === 'A') {
          const dataAttributes = target.dataset
          trackClick({
            elementId: 'edPromoProgramLessonLink',
            extra: dataAttributes,
          })
        }
      }}
    >
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('sectionTitle')}</span>
        </h1>

        <div className={css.text}>{t('intro')}</div>

        <div className={css.plans}>
          {plansWithLessons.map((plan) =>
            plan.deprecated ? null : (
              <div className={cn({ [css.plan]: true, [css.notpro]: plan.name !== 'pro' })} key={plan.name}>
                <div className={css.planInfoAndFirstTenLessons}>
                  {plansWithLessons.length > 1 && (
                    <>
                      <div className={css.planInfo}>
                        <h3 className={css.planTitle}>{t(`plans.${plan.name}.title`)}</h3>
                        <p className={css.planDesc}>
                          {!!plan.defaultPriceUsd && (
                            <>
                              {t('price')}:{' '}
                              <CurrencySwitchPrice>
                                {toMoneyWithCurrency({
                                  usd: plan.defaultPriceUsd,
                                  rub: plan.defaultPriceRub,
                                  amd: plan.defaultPriceAmd,
                                })}
                              </CurrencySwitchPrice>
                              <br />
                            </>
                          )}
                          {plan.name === 'free' &&
                            t('plans.free.description', {
                              hours: plural(
                                getHoursFromDuration(plan.videosDuration),
                                tg('hours', { returnObjects: true })
                              ),
                            })}
                          {plan.name === 'basic' &&
                            t('plans.basic.description', {
                              hours: plural(
                                getHoursFromDuration(plan.videosDuration),
                                tg('hours', { returnObjects: true })
                              ),
                            })}
                          {plan.name === 'pro' &&
                            t('plans.pro.description', {
                              hours: plural(
                                getHoursFromDuration(plan.videosDuration),
                                tg('hours', { returnObjects: true })
                              ),
                            })}
                        </p>
                      </div>
                    </>
                  )}
                  <ol className={css.lessons}>
                    {[...plan.lessons].slice(0, 10).map(({ title, slug, index, videoDurationString }) => (
                      <li className={css.lessonOuter} key={slug}>
                        <span className={css.lesson} key={slug}>
                          <span className={css.lessonLabel}>{index + 1}.</span>
                          <Link
                            to={getGuideLessonRoute({
                              guideSlug: guide.slug,
                              lessonSlug: slug,
                            })}
                            className={css.lessonLink}
                            data-lesson-slug={slug}
                            data-lesson-index={index}
                          >
                            {title.trim()}&nbsp;<span className={css.lessonVideoDuration}>{videoDurationString}</span>
                          </Link>
                        </span>
                      </li>
                    ))}
                  </ol>
                </div>
                {plan.lessons.length > 10 && (
                  <ol className={css.lessons}>
                    {plan.lessons.slice(10).map(({ title, slug, index, videoDurationString }) => (
                      <li className={css.lessonOuter} key={slug}>
                        <span className={css.lesson} key={slug}>
                          <span className={css.lessonLabel}>{index + 1}.</span>
                          <Link
                            to={getGuideLessonRoute({
                              guideSlug: guide.slug,
                              lessonSlug: slug,
                            })}
                            className={css.lessonLink}
                            data-lesson-slug={slug}
                            data-lesson-index={index}
                          >
                            {title.trim()}&nbsp;<span className={css.lessonVideoDuration}>{videoDurationString}</span>
                          </Link>
                        </span>
                      </li>
                    ))}
                  </ol>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}
