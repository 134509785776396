export const DocsFooterRu = () => {
  return (
    <>
      <hr />
      <p>
        <b>Организация:</b> ИП "Сергей Дмитриев Игоревич"
        <br />
        <b>Директор:</b> Сергей Дмитриев Игоревич
        <br />
        <b>Адрес:</b> Республика Армения, Ереван, ул. Хоренаци, 26а, 210
        <br />
        <b>Регистрирующий орган:</b> Министерство юстиции Республики Армения, государственный реестр юридических лиц
        <br />
        <b>Дата регистрации:</b> 09.10.2023
        <br />
        <b>ИНН:</b> 23379808
        <br />
        <b>Регистрационный номер:</b> 286.1345750
        <br />
      </p>
    </>
  )
}

export const DocsFooterEn = () => {
  return (
    <>
      <hr />
      <p>
        <b>Organization:</b> PE "Sergey Dmitriev Igorevich"
        <br />
        <b>Director:</b> Sergey Dmitriev Igorevich
        <br />
        <b>Address:</b> Republic of Armenia, Yerevan 26а Khorenatsi st., 210
        <br />
        <b>Registration Entity:</b> Republic of Armenia ministry of justice state register of juridical persons
        <br />
        <b>Date of registration:</b> 09.10.2023
        <br />
        <b>Tax number:</b> 23379808
        <br />
        <b>Registration number:</b> 286.1345750
      </p>
    </>
  )
}

export const DocsFooterHy = () => {
  return (
    <>
      <hr />
      <p>
        <b>Կազմակերպություն:</b> ԱՁ "Սերգեյ Դմիտրիև Իգորևիչ"
        <br />
        <b>Տնօրեն:</b> Սերգեյ Դմիտրիև Իգորևիչ
        <br />
        <b>Հասցե:</b> Հայաստանի Հանրապետություն, Երևան, Խորենացի փողոց, 26ա, 210
        <br />
        <b>Գրանցող մարմին:</b> Հայաստանի Հանրապետության արդարադատության նախարարություն, իրավաբանական անձանց պետական
        ռեեստր
        <br />
        <b>Գրանցման ամսաթիվ:</b> 09.10.2023
        <br />
        <b>ՀՎՀՀ:</b> 23379808
        <br />
        <b>Գրանցման համար:</b> 286.1345750
        <br />
      </p>
    </>
  )
}
