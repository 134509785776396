import { useParams } from 'react-router-dom'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { usePromoCodeValue } from '../../../lib/promoCode'
import { type GuideHomeRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { GuidePlans } from '../GuidePlans'

export const GuidePlansPage = withPageWrapper({
  title: ({ t }) => t('guidePlansPage.title'),
  eventsContext: ({ queryResult }) => ({
    guide: queryResult.data?.guide,
  }),
  useQuery: () => {
    const { promoCodeValue } = usePromoCodeValue()
    const { guideSlug } = useParams() as GuideHomeRouteParams
    return trpc.getStudentGuideFull.useQuery(
      {
        guideSlug,
        promoCodeValue,
      },
      {
        staleTime: 3000,
      }
    )
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    guide: queryResult.data.guide,
  }),
  langs: ['ru', 'en', 'hy'],
})(({ guide }) => {
  const { t } = useT('guidePlansPage')

  return (
    <Segment title={t('title')}>
      <GuidePlans guideSlug={guide.slug} />
    </Segment>
  )
})
