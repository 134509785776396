import { EdCard } from '../../../components/education/cards/EdCard'
import { Alert } from '../../../components/ui/Alert'
import { Cards } from '../../../components/ui/Card'
import { type ColumnFillerItemType } from '../../../components/ui/ColumnFiller'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import { ConsultationsPageInstanceCard } from '../../consultation/ConsultationsPage/ConsultationsPageInstances'
import css from './index.module.scss'

export const DashboardPage = withPageWrapper({
  title: ({ t }) => t('dashboardPage.title'),
  authorizedOnly: true,
  showLoaderOnFetching: false,
  useQuery: () => {
    return trpc.getDashboardItems.useQuery()
  },
  setProps: ({ queryResult }) => {
    return {
      edItems: queryResult.data.edItems,
      consultationInstances: queryResult.data.consultationInstances,
    }
  },
  langs: ['ru', 'en', 'hy'],
})(({ edItems, consultationInstances }) => {
  const { t } = useT('dashboardPage')
  return (
    <Segment title={t('title')}>
      <div className={css.alert}>
        <Alert
          color="brown"
          message={
            <>
              <p>{t('intro.part1')}</p>
              <p>{t('intro.part2')}</p>
            </>
          }
        />
      </div>

      {edItems.length + consultationInstances.length ? (
        <div className={css.items}>
          <Cards
            items={[
              ...edItems.map(
                (edItem) =>
                  ({
                    key: edItem.id,
                    render: ({ setItemRef }) => <EdCard edItem={edItem} setRef={setItemRef} />,
                  } as ColumnFillerItemType)
              ),
              ...consultationInstances.map(
                (consultationInstance) =>
                  ({
                    key: consultationInstance.id,
                    render: ({ setItemRef }) => (
                      <ConsultationsPageInstanceCard setRef={setItemRef} consultationInstance={consultationInstance} />
                    ),
                  } as ColumnFillerItemType)
              ),
            ]}
          />
        </div>
      ) : (
        <div className={css.alert}>
          <Alert color="brown" message={<>{t('noData')}</>} />
        </div>
      )}
    </Segment>
  )
})
