export const parseKinescopeVideoIdOrUrl = (kinescopeVideoUrlOrId: string) => {
  if (!kinescopeVideoUrlOrId.startsWith('http')) {
    // TODO: fix this if needed
    kinescopeVideoUrlOrId = `http://${kinescopeVideoUrlOrId}?v=${kinescopeVideoUrlOrId}`
  }
  const url = new URL(kinescopeVideoUrlOrId)
  const id = url.searchParams.get('v')
  const widthString = url.searchParams.get('width')
  const heightString = url.searchParams.get('height')
  const width = widthString ? parseInt(widthString) : 560
  const height = heightString ? parseInt(heightString) : 364
  return { id, width, height }
}

export const getKinescopeVideoEmbedUrl = ({ id, params }: { id: string; params?: {} }) => {
  const parsed = parseKinescopeVideoIdOrUrl(id)
  const url = new URL(`https://kinescope.io/embed/${parsed.id}`)
  return url.toString()
}

export const getKinescopeVideoSize = ({ id, width, height }: { id: string; width?: number; height?: number }) => {
  const parsed = parseKinescopeVideoIdOrUrl(id)
  width = width || parsed.width
  height = height || parsed.height
  const ratio = height / width
  const ratioPercent = ratio * 100
  const ratioPercentString = `${ratioPercent.toFixed(2)}%`
  return {
    width,
    height,
    ratio,
    ratioPercent,
    ratioPercentString,
  }
}
