export const parseVkVideoIdOrUrl = (vkVideoUrlOrId: string) => {
  if (!vkVideoUrlOrId.startsWith('http')) {
    vkVideoUrlOrId = `https://vkvideo.ru/${vkVideoUrlOrId}`
  }
  const url = new URL(vkVideoUrlOrId)
  const pathParts = url.pathname.split('/')
  const lastPathPart = pathParts[pathParts.length - 1]
  const [oid, id] = lastPathPart.replace('video', '').split('_')
  const widthString = url.searchParams.get('width')
  const heightString = url.searchParams.get('height')
  const width = widthString ? parseInt(widthString) : 560
  const height = heightString ? parseInt(heightString) : 364
  return { id, oid, width, height }
}

export const getVkVideoSize = ({ id, width, height }: { id: string; width?: number; height?: number }) => {
  const parsed = parseVkVideoIdOrUrl(id)
  width = width || parsed.width
  height = height || parsed.height
  const ratio = height / width
  const ratioPercent = ratio * 100
  const ratioPercentString = `${ratioPercent.toFixed(2)}%`
  return {
    width,
    height,
    ratio,
    ratioPercent,
    ratioPercentString,
  }
}

export const getVkVideoEmbedUrl = ({ id, params }: { id: string; params?: {} }) => {
  const parsed = parseVkVideoIdOrUrl(id)
  const url = new URL(`https://vkvideo.ru/video_ext.php?oid=${parsed.oid}&id=${parsed.id}&hd=2`)
  return url.toString()
}
