/* eslint-disable @typescript-eslint/no-unused-vars */
import { useStore } from '@nanostores/react'
import { zSignUpInput } from '@svag/backend/src/router/auth/signUp/input'
import { getCountriesSelectOptions } from '@svag/shared/src/countries'
import { zPasswordsMustBeTheSame, zStringOptional, zStringRequired } from '@svag/shared/src/zod'
import Cookies from 'js-cookie'
import { action, atom } from 'nanostores'
import { useNavigate } from 'react-router-dom'
import z from 'zod'
import { FormItems } from '../../../components/form/FormItems'
import { Input } from '../../../components/form/Input'
import { Alert } from '../../../components/ui/Alert'
import { Button, Buttons } from '../../../components/ui/Button'
import { clearCacheRecord, useCacheRecord, useSyncCacheRecord } from '../../../lib/cache'
import { useClient } from '../../../lib/ctx'
import { useCurrency } from '../../../lib/currency'
import { useForm } from '../../../lib/form'
import { replaceLangInRoutePathname, setLangToCookies, useLang, useT } from '../../../lib/i18n'
import { mixpanelAlias, trackSignUp, trackSignUpStart } from '../../../lib/mixpanel'
import { getTermsRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { Select } from '../../form/Select'
import { lastVisistedNotAuthRouteStore } from '../../other/NotAuthRouteTracker'
import { closeAuthModal } from '../Auth'
import css from './index.module.scss'

type Step = 'initial' | 'confirmation'
type SignUpState = {
  step: Step
  input: any
  promptMessage: string
}
const initialState: SignUpState = {
  step: 'initial',
  input: {},
  promptMessage: '',
}
const signUpStore = atom<SignUpState>(initialState)
const updateState = action(signUpStore, 'updateState', (store, state: SignUpState) => {
  store.set({ ...store.get(), ...state })
})
const resetState = action(signUpStore, 'resetState', (store) => {
  store.set(initialState)
})

const SignUpInitial = ({
  isSimple,
  interests,
  onSuccess,
}: {
  isSimple: boolean
  interests?: string[]
  onSuccess?: null | (() => void | Promise<void>)
}) => {
  const { lang } = useLang()
  const { currency } = useCurrency()
  const requestVerificationCode = trpc.requestVerificationCode.useMutation()
  const lastVisistedNotAuthRoute = useStore(lastVisistedNotAuthRouteStore)
  const navigate = useNavigate()
  const trpcUtils = trpc.useContext()
  const signUpWithoutConfirmation = trpc.signUpWithoutConfirmation.useMutation()

  const { country } = useClient()
  const { cacheRecord } = useCacheRecord({
    key: 'authForm',
    defaultValue: { name: '', email: '', phone: '', country: country || '' },
  })
  const { t } = useT('signUp')
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      ...cacheRecord,
      lang,
      currency,
      password: '',
      passwordAgain: '',
    },
    // initialValues: {
    //   ...cacheRecord,
    //   name: 'XXX',
    //   email: Math.random().toString() + '@gmail.com',
    //   phone: Math.random().toString().slice(2, 12),
    //   lang,
    //   currency,
    //   password: '1234',
    //   passwordAgain: '1234',
    //   country: 'RU',
    // },
    // enableReinitialize: true,
    validationSchema: zSignUpInput
      .extend({
        passwordAgain: isSimple ? zStringOptional : zStringRequired,
      })
      .omit({ verificationCode: true })
      .superRefine(zPasswordsMustBeTheSame('password', 'passwordAgain')),
    onSubmit: async ({ name, email, password, country, phone, lang, currency }) => {
      const input = { name, email, password, country: country || '', phone, lang, currency, interests }
      trackSignUpStart({ name, phone, email })
      // const { promptMessage, ok } = await requestVerificationCode.mutateAsync({
      //   phone,
      //   procedureName: 'signUp',
      //   procedureInput: input,
      // })
      // if (ok) {
      //   updateState({
      //     step: 'confirmation',
      //     input,
      //     promptMessage,
      //   })
      // } else {
      const { token, userId, userLang } = await signUpWithoutConfirmation.mutateAsync({ ...input })
      mixpanelAlias(userId)
      trackSignUp({ name: input.name, phone: input.phone, email: input.email })
      Cookies.set('token-svag', token, { expires: 99999 })
      void trpcUtils.invalidate().then(async () => {
        return await onSuccess?.()
      })
      closeAuthModal()
      clearCacheRecord({
        key: 'authForm',
      })
      setLangToCookies(userLang)
      resetState()
      navigate(replaceLangInRoutePathname(lastVisistedNotAuthRoute, userLang), { replace: true })
      // }
    },
  })
  useSyncCacheRecord({
    key: 'authForm',
    source: formik.values,
    toPickFromSource: ['name', 'email', 'phone', 'country'],
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Input floatingPlaceholder={t('namePlaceholder')} name="name" formik={formik} />
        {!isSimple && (
          <Select
            floatingPlaceholder={t('countryPlaceholder')}
            options={getCountriesSelectOptions()}
            name="country"
            formik={formik}
          />
        )}
        <Input floatingPlaceholder={t('emailPlaceholder')} name="email" formik={formik} />
        <Input floatingPlaceholder={t('phonePlaceholder')} name="phone" formik={formik} />
        {!isSimple && (
          <>
            <Input floatingPlaceholder={t('passwordPlaceholder')} name="password" type="password" formik={formik} />
            <Input
              floatingPlaceholder={t('passwordAgainPlaceholder')}
              name="passwordAgain"
              type="password"
              formik={formik}
            />
          </>
        )}
        <Alert {...alertProps} />
        <Button type="submit" {...buttonProps}>
          {t('registerButton')}
        </Button>
      </FormItems>
    </form>
  )
}

const SignUpConfirmation = ({ onSuccess }: { onSuccess?: null | (() => void | Promise<void>) }) => {
  const signUpState = useStore(signUpStore)
  const lastVisistedNotAuthRoute = useStore(lastVisistedNotAuthRouteStore)
  const navigate = useNavigate()
  const trpcUtils = trpc.useContext()
  const signUp = trpc.signUp.useMutation()
  const { t } = useT('signUp')
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      verificationCode: '',
    },
    validationSchema: z.object({
      verificationCode: zStringRequired,
    }),
    onSubmit: async ({ verificationCode }) => {
      const { token, userId, userLang } = await signUp.mutateAsync({ ...signUpState.input, verificationCode })
      mixpanelAlias(userId)
      trackSignUp({ name: signUpState.input.name, phone: signUpState.input.phone, email: signUpState.input.email })
      Cookies.set('token-svag', token, { expires: 99999 })
      void trpcUtils.invalidate().then(async () => {
        return await onSuccess?.()
      })
      closeAuthModal()
      clearCacheRecord({
        key: 'authForm',
      })
      setLangToCookies(userLang)
      resetState()
      navigate(replaceLangInRoutePathname(lastVisistedNotAuthRoute, userLang), { replace: true })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormItems>
        <Input
          floatingPlaceholder={t('verificationCodePlaceholder')}
          name="verificationCode"
          hint={signUpState.promptMessage}
          formik={formik}
        />
        <Alert {...alertProps} />
        <Buttons>
          <Button type="submit" {...buttonProps}>
            {t('continueButton')}
          </Button>
          <Button
            color="brandAccentLight"
            onClick={() => {
              formik.resetForm()
              resetState()
            }}
          >
            {t('backButton')}
          </Button>
        </Buttons>
      </FormItems>
    </form>
  )
}

export const SignUp = ({
  onSuccess,
  isSimple,
  interests,
}: {
  onSuccess?: null | (() => void | Promise<void>)
  isSimple: boolean
  interests?: string[]
}) => {
  const signUpState = useStore(signUpStore)
  const { t } = useT('signUp')

  return (
    <FormItems>
      {signUpState.step === 'initial' && (
        <SignUpInitial isSimple={isSimple} interests={interests} onSuccess={onSuccess} />
      )}
      {signUpState.step === 'confirmation' && <SignUpConfirmation onSuccess={onSuccess} />}
      <p className={css.rules}>
        {t('registrationAgreement')}{' '}
        <a href={getTermsRoute()} target="_blank" rel="noreferrer">
          {t('termsOfServiceLink')}
        </a>
      </p>
    </FormItems>
  )
}
