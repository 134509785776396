import cn from 'classnames'
import { useState } from 'react'
import { useLang } from '../../../lib/i18n'
import { VideoKinescope } from '../VideoKinescope'
import { VideoVk } from '../VideoVk'
import { VideoYoutube } from '../VideoYoutube'
import css from './index.module.scss'

export const VideoAny = ({
  vkId,
  youtubeId,
  youtubeEnId,
  kinescopeId,
  width,
  height,
  className,
  eventElementId,
  eventElementGroup,
  withTopPadding,
}: {
  vkId?: string | null
  youtubeId?: string | null
  youtubeEnId?: string | null
  kinescopeId?: string | null
  width?: number
  height?: number
  className?: string
  eventElementId?: string
  eventElementGroup?: string
  withTopPadding?: boolean
}) => {
  const { lang } = useLang()
  const hideKinescope = !!vkId
  const [provider, setProvider] = useState<'kinescope' | 'youtube' | 'vk' | null>(
    lang === 'en'
      ? youtubeEnId
        ? 'youtube'
        : null
      : youtubeId
      ? 'youtube'
      : vkId
      ? 'vk'
      : kinescopeId
      ? 'kinescope'
      : null
  )
  const idsCount =
    lang === 'en'
      ? [youtubeEnId].filter(Boolean).length
      : [vkId, youtubeId, !hideKinescope && kinescopeId].filter(Boolean).length
  if (!idsCount) {
    return null
  }
  return (
    <div className={cn(css.videoOuter, className, { [css.withTopPadding]: withTopPadding && idsCount > 1 })}>
      <div className={css.videoWithSelect}>
        {idsCount > 1 && (
          <div className={css.select}>
            {youtubeId && (
              <button
                className={cn({ [css.selectItem]: true, [css.active]: provider === 'youtube' })}
                onClick={() => {
                  setProvider('youtube')
                }}
              >
                YouTube
              </button>
            )}
            {vkId && (
              <button
                className={cn({ [css.selectItem]: true, [css.active]: provider === 'vk' })}
                onClick={() => {
                  setProvider('vk')
                }}
              >
                VK
              </button>
            )}
            {!hideKinescope && kinescopeId && (
              <button
                className={cn({ [css.selectItem]: true, [css.active]: provider === 'kinescope' })}
                onClick={() => {
                  setProvider('kinescope')
                }}
              >
                Kinescope
              </button>
            )}
          </div>
        )}
        {lang === 'en' ? (
          <div className={css.video}>
            {provider === 'youtube' && youtubeEnId ? (
              <VideoYoutube id={youtubeEnId} width={width} height={height} />
            ) : null}
          </div>
        ) : (
          <div className={css.video}>
            {provider === 'kinescope' && kinescopeId ? (
              <VideoKinescope
                videoId={kinescopeId}
                width={width}
                height={height}
                eventElementId={eventElementId}
                eventElementGroup={eventElementGroup}
              />
            ) : provider === 'youtube' && youtubeId ? (
              <VideoYoutube id={youtubeId} width={width} height={height} />
            ) : provider === 'vk' && vkId ? (
              <VideoVk id={vkId} width={width} height={height} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}
