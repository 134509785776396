import { Auth } from '../../../components/auth/Auth'
import { withPageWrapper } from '../../../lib/pageWrapper'

export const SignUpPage = withPageWrapper({
  title: ({ t }) => t('signUpPage.title'),
  redirectAuthorized: true,
  langs: ['ru', 'en', 'hy'],
})(() => {
  return <Auth policy="page" />
})
