import { zCheckResetPasswordTokenInput } from '@svag/backend/src/router/auth/checkResetPasswordToken/input'
import { zResetPasswordByEmailInput } from '@svag/backend/src/router/auth/resetPasswordByEmail/input'
import { zPasswordsMustBeTheSame, zStringRequired } from '@svag/shared/src/zod'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormItems } from '../../../components/form/FormItems'
import { Input } from '../../../components/form/Input'
import { Alert } from '../../../components/ui/Alert'
import { Button, Buttons } from '../../../components/ui/Button'
import { Loader } from '../../../components/ui/Loader'
import { Segment } from '../../../components/ui/Segment'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getSignInRoute, type ResetPasswordRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const ResetPasswordPage = withPageWrapper({
  title: ({ t }) => t('resetPasswordPage.setNewPasswordTitle'),
  langs: ['ru', 'en', 'hy'],
})(() => {
  const { t } = useT('resetPasswordPage')
  const { resetPasswordToken } = useParams() as ResetPasswordRouteParams
  const resetPassword = trpc.resetPasswordByEmail.useMutation()
  const checkResetPasswordToken = trpc.checkResetPasswordToken.useMutation()
  const [valid, setValid] = useState(false)
  const [finished, setFinished] = useState(false)

  const checkResetPasswordTokenForm = useForm({
    initialValues: {
      resetPasswordToken,
    },
    validationSchema: zCheckResetPasswordTokenInput,
    onSubmit: async (values) => {
      await checkResetPasswordToken.mutateAsync(values)
      setValid(true)
    },
    resetOnSuccess: false,
    successMessage: false,
  })

  const resetPasswordForm = useForm({
    initialValues: {
      resetPasswordToken,
      newPassword: '',
      newPasswordAgain: '',
    },
    validationSchema: zResetPasswordByEmailInput
      .extend({
        newPasswordAgain: zStringRequired,
      })
      .superRefine(zPasswordsMustBeTheSame('newPassword', 'newPasswordAgain')),
    onSubmit: async (values) => {
      await resetPassword.mutateAsync(values)
      setFinished(true)
    },
    successMessageDuration: false,
    resetOnSuccess: false,
    successMessage: false,
  })

  useEffect(() => {
    checkResetPasswordTokenForm.formik.handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!checkResetPasswordTokenForm.formik.submitCount || checkResetPasswordTokenForm.formik.isSubmitting) {
    return <Loader type="page" />
  }

  if (!valid) {
    return (
      <Segment title={t('invalidTokenTitle')}>
        <Alert {...checkResetPasswordTokenForm.alertProps} />
      </Segment>
    )
  }

  if (!finished) {
    return (
      <Segment title={t('setNewPasswordTitle')}>
        <form onSubmit={resetPasswordForm.formik.handleSubmit}>
          <FormItems>
            <Input
              floatingPlaceholder={t('placeholders.newPassword')}
              name="newPassword"
              type="password"
              formik={resetPasswordForm.formik}
            />
            <Input
              floatingPlaceholder={t('placeholders.repeatNewPassword')}
              name="newPasswordAgain"
              type="password"
              formik={resetPasswordForm.formik}
            />
            <Alert {...resetPasswordForm.alertProps} />
            <Buttons>
              <Button type="submit" {...resetPasswordForm.buttonProps}>
                {t('setNewPasswordButton')}
              </Button>
            </Buttons>
          </FormItems>
        </form>
      </Segment>
    )
  }

  return (
    <Segment title={t('passwordResetSuccessTitle')}>
      <FormItems>
        <Alert color="green" message={t('passwordResetSuccessMessage')} />
        <Buttons>
          <Button to={getSignInRoute()}>{t('signInButton')}</Button>
        </Buttons>
      </FormItems>
    </Segment>
  )
})
