import { createElement } from 'react'
import { type IconBaseProps } from 'react-icons'
import { ReactComponent as BackArrow } from '../../../assets/icons/backArrow.svg'
import { ReactComponent as Burger } from '../../../assets/icons/burger.svg'
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg'
import { ReactComponent as CheckboxTick } from '../../../assets/icons/checkboxTick.svg'
import { ReactComponent as ChevronRight } from '../../../assets/icons/chevronRight.svg'
import { ReactComponent as Code } from '../../../assets/icons/code.svg'
import { ReactComponent as CoinAmd } from '../../../assets/icons/coinAmd.svg'
import { ReactComponent as CoinRub } from '../../../assets/icons/coinRub.svg'
import { ReactComponent as CoinUsd } from '../../../assets/icons/coinUsd.svg'
import { ReactComponent as Community } from '../../../assets/icons/community.svg'
import { ReactComponent as Consultation } from '../../../assets/icons/consultation.svg'
import { ReactComponent as CrossMenu } from '../../../assets/icons/crossMenu.svg'
import { ReactComponent as DevtoRound } from '../../../assets/icons/devtoRound.svg'
import { ReactComponent as FacebookRound } from '../../../assets/icons/facebookRound.svg'
import { ReactComponent as Finger } from '../../../assets/icons/finger.svg'
import { ReactComponent as Globe } from '../../../assets/icons/globe.svg'
import { ReactComponent as Group } from '../../../assets/icons/group.svg'
import { ReactComponent as Guide } from '../../../assets/icons/guide.svg'
import { ReactComponent as HabrRound } from '../../../assets/icons/habrRound.svg'
import { ReactComponent as Hey } from '../../../assets/icons/hey.svg'
import { ReactComponent as Info } from '../../../assets/icons/info.svg'
import { ReactComponent as InstagramRound } from '../../../assets/icons/instagramRound.svg'
import { ReactComponent as Items } from '../../../assets/icons/items.svg'
import { ReactComponent as Link } from '../../../assets/icons/link.svg'
import { ReactComponent as LinkedInRound } from '../../../assets/icons/linkedinRound.svg'
import { ReactComponent as LinkedInSquare } from '../../../assets/icons/linkedinSquare.svg'
import { ReactComponent as Lock } from '../../../assets/icons/lock.svg'
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg'
import { ReactComponent as RutubeRound } from '../../../assets/icons/rutubeRound.svg'
import { ReactComponent as Target } from '../../../assets/icons/target.svg'
import { ReactComponent as TelegramRound } from '../../../assets/icons/telegramRound.svg'
import { ReactComponent as Terminal } from '../../../assets/icons/terminal.svg'
import { ReactComponent as Tick } from '../../../assets/icons/tick.svg'
import { ReactComponent as User } from '../../../assets/icons/user.svg'
import { ReactComponent as VkontakteRound } from '../../../assets/icons/vkontakteRound.svg'
import { ReactComponent as Wallet } from '../../../assets/icons/wallet.svg'
import { ReactComponent as XRound } from '../../../assets/icons/xRound.svg'
import { ReactComponent as YoutubeRound } from '../../../assets/icons/youtubeRound.svg'

const icons = {
  group: Group,
  consultation: Consultation,
  guide: Guide,
  backArrow: BackArrow,
  code: Code,
  items: Items,
  info: Info,
  lock: Lock,
  tick: Tick,
  checkboxTick: CheckboxTick,
  crossMenu: CrossMenu,
  burger: Burger,
  finger: Finger,
  link: Link,
  target: Target,
  terminal: Terminal,
  hey: Hey,
  wallet: Wallet,
  calendar: Calendar,
  linkedin: LinkedInSquare,
  vkontakte: VkontakteRound,
  facebook: FacebookRound,
  telegram: TelegramRound,
  vkontakteRound: VkontakteRound,
  facebookRound: FacebookRound,
  telegramRound: TelegramRound,
  linkedinRound: LinkedInRound,
  instagramRound: InstagramRound,
  community: Community,
  user: User,
  logout: Logout,
  globe: Globe,
  coinRub: CoinRub,
  coinUsd: CoinUsd,
  coinAmd: CoinAmd,
  chevronRight: ChevronRight,
  xRound: XRound,
  rutubeRound: RutubeRound,
  youtubeRound: YoutubeRound,
  habrRound: HabrRound,
  devtoRound: DevtoRound,
}

export type IconName = keyof typeof icons

export const Icon: React.FC<{ name: keyof typeof icons } & IconBaseProps> = ({ name, ...restProps }) => {
  if (!icons[name]) {
    console.error(`Icon "${name}" not found`)
    return null
  }
  return createElement(icons[name], restProps)
}
