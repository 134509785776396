import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EdCards } from '../../../components/education/cards/EdCard'
import { Icon } from '../../../components/ui/Icon'
import { RichText } from '../../../components/ui/RichText'
import { Segment } from '../../../components/ui/Segment'
import { trackViewArticleOneMinute, trackVisitArticle } from '../../../lib/mixpanel'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type getArticleRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { type RouteParams } from '../../../utils/pumpGetRoute'
import css from './index.module.scss'

export const ArticlePage = withPageWrapper({
  title: ({ queryResult }) => queryResult.data.article.title,
  showLoaderOnFetching: false,
  eventsContext: ({ queryResult }) => ({
    article: queryResult.data.article,
  }),
  useQuery: () => {
    const { articleSlug } = useParams() as RouteParams<typeof getArticleRoute>
    return trpc.getArticle.useQuery({
      articleSlug,
    })
  },
  setProps: ({ queryResult }) => {
    return {
      article: queryResult.data.article,
    }
  },
  langs: ['ru', 'en', 'hy'],
})(({ article }) => {
  useEffect(() => {
    trackVisitArticle({
      article,
    })
    const timeout = setTimeout(() => {
      trackViewArticleOneMinute({
        article,
      })
    }, 60 * 1000)
    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article.slug])

  return (
    <Segment title={article.title}>
      <RichText html={article.contentHTML} />
      <div className={css.author}>
        <img className={css.avatar} width="88" height="88" src="/images/team/s4.png" alt="" />
        <div className={css.text}>
          <h2 className={css.title}>Сергей Дмитриев</h2>
          <ul className={css.list}>
            <li className={css.listItem}>Опыт коммерческой разработки 14&nbsp;лет</li>{' '}
            <li className={css.listItem}>Специализируется на&nbsp;стартапах 7&nbsp;лет</li>{' '}
            <li className={css.listItem}>Реализовал 8&nbsp;крупных проектов</li>{' '}
            <li className={css.listItem}>Сооснователь 3&nbsp;стартапов</li>
          </ul>
          <div className={css.socials}>
            {article.author.linkedin && (
              <a href={article.author.linkedin} className={css.social}>
                <Icon width={32} height={32} className={css.icon} name="linkedinRound" />
              </a>
            )}
            {article.author.facebook && (
              <a href={article.author.facebook} className={css.social}>
                <Icon width={32} height={32} className={css.icon} name="facebookRound" />
              </a>
            )}
            {article.author.instagram && (
              <a href={article.author.instagram} className={css.social}>
                <Icon width={32} height={32} className={css.icon} name="instagramRound" />
              </a>
            )}
            {article.author.vkontakte && (
              <a href={article.author.vkontakte} className={css.social}>
                <Icon width={32} height={32} className={css.icon} name="vkontakteRound" />
              </a>
            )}
            {article.author.telegram && (
              <a href={article.author.telegram} className={css.social}>
                <Icon width={32} height={32} className={css.icon} name="telegramRound" />
              </a>
            )}
          </div>
        </div>
      </div>
      <EdCards
        className={css.related}
        edItems={article.edItems}
        getColumnsCount={({ items, windowWidth }) =>
          items.length < 2 ? 1 : items.length < 3 ? 2 : windowWidth > 1100 ? 3 : windowWidth > 900 ? 2 : 1
        }
      />
    </Segment>
  )
})
