import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { Button } from '../../../../components/ui/Button'
import { LabelValueInfo } from '../../../../components/ui/LabelValueInfo'
import { Segment, Segments } from '../../../../components/ui/Segment'
import { useCurrency } from '../../../../lib/currency'
import { useT } from '../../../../lib/i18n'
import { getNewGroupStudentRoute } from '../../../../lib/routes'
import { GroupPageActiveStudents } from '../GroupPageActiveStudents'
import css from './index.module.scss'

export const GroupPageTeacher = ({
  groupInstance,
}: {
  groupInstance: TrpcRouterOutput['getGroupInstance']['groupInstance']
}) => {
  const { t } = useT('groupPageTeacher')
  const { toMoneyWithCurrency } = useCurrency()
  return (
    <Segment
      title={`${groupInstance.title}`}
      rightRender={
        <Button
          nowrap
          to={getNewGroupStudentRoute({
            groupInstanceSlug: groupInstance.slug,
          })}
        >
          {t('newStudentButton')}
        </Button>
      }
    >
      <Segments>
        <Segment size="M" title={t('infoSectionTitle')}>
          <div className={css.infoAndButtons}>
            <div className={css.info}>
              <LabelValueInfo
                size="m"
                items={[
                  {
                    label: t('aboutGroup'),
                    value: groupInstance.desc,
                  },
                  {
                    label: t('costFor30Days'),
                    value: toMoneyWithCurrency({
                      usd: groupInstance.priceUsd,
                      rub: groupInstance.priceRub,
                      amd: groupInstance.priceAmd,
                    }),
                  },
                ]}
              />
            </div>
          </div>
        </Segment>
        <GroupPageActiveStudents groupInstance={groupInstance} />
      </Segments>
    </Segment>
  )
}
