import { RichText } from '../../components/ui/RichText'
import { useLang } from '../../lib/i18n'
import { withPageWrapper } from '../../lib/pageWrapper'
import { DocsFooterEn, DocsFooterHy, DocsFooterRu } from './DocsFooter'

const Ru = () => {
  return (
    <>
      <h1>Политика конфиденциальности</h1>
      <p>Последнее обновление: 17.10.2023</p>
      <p>
        Настоящая Политика конфиденциальности регулирует использование нашего сайта, расположенного по адресу{' '}
        <a href="https://svag.group">https://svag.group</a> ("Веб-сайт"), управляемого ИП "Сергей Дмитриев Игоревич",
        индивидуальным предпринимателем Армении, именуемым в дальнейшем "SVAG", "мы", "нас" или "наш".
      </p>
      <h3>Собираемая информация</h3>
      <ul>
        <li>
          Персональная информация: Мы можем собирать персональную информацию, такую как имя, адрес электронной почты и
          платежные данные, при регистрации или совершении покупки.
        </li>
        <li>Данные об использовании: Мы можем собирать данные о том, как вы используете наш сайт.</li>
        <li>Файлы cookie: Мы используем файлы cookie для улучшения пользовательского опыта.</li>
      </ul>

      <h3>Как мы используем вашу информацию</h3>
      <p>
        Мы используем собранную информацию для различных целей, включая, но не ограничиваясь предоставлением услуг,
        идентификацией и коммуникацией с вами, а также улучшением наших услуг.
      </p>

      <h3>Безопасность</h3>
      <p>Мы серьезно относимся к безопасности ваших данных и применяем необходимые меры безопасности для их защиты.</p>

      <h3>Сервисы третьих сторон</h3>
      <p>
        Мы можем привлекать услуги третьих сторон для выполнения наших услуг, и они могут иметь доступ к вашей
        персональной информации.
      </p>

      <h3>Ваши права</h3>
      <p>
        У вас есть право на доступ, обновление или удаление вашей персональной информации. Вы можете сделать это, войдя
        в настройки вашего аккаунта.
      </p>

      <h3>Изменения в этой политике</h3>
      <p>
        Мы оставляем за собой право вносить изменения в данную Политику конфиденциальности в любое время. Любые
        изменения будут обновлены на этой странице.
      </p>

      <h3>Свяжитесь с нами</h3>
      <p>
        Если у вас есть вопросы по этой Политике конфиденциальности, пожалуйста, свяжитесь с нами по адресу{' '}
        <a href="mailto:manager@svag.group">manager@svag.group</a>
      </p>
      <DocsFooterRu />
    </>
  )
}

const En = () => {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p>Last updated: 17.10.2023</p>
      <p>
        This Privacy Policy governs the use of our website located at{' '}
        <a href="https://svag.group">https://svag.group</a> ("Website") operated by PE "Sergey Dmitriev Igorevich", the
        individual entrepreneur of Armenia, hereinafter referred to as "SVAG", "us", "we" or "our".
      </p>
      <h3>Information We Collect</h3>
      <ul>
        <li>
          Personal Information: We may collect personal information such as name, email address, and payment information
          when you register or make a purchase.
        </li>
        <li>Usage Data: We may collect data about how you use our website.</li>
        <li>Cookies: We use cookies to enhance user experience.</li>
      </ul>

      <h3>How We Use Your Information</h3>
      <p>
        We use the collected information for various purposes including but not limited to providing the services,
        identifying and communicating with you, and improving our services.
      </p>

      <h3>Security</h3>
      <p>We take the security of your data seriously and implement necessary security measures to protect it.</p>

      <h3>Third-Party Services</h3>
      <p>
        We may employ third-party services to facilitate our service, and they may have access to your personal
        information.
      </p>

      <h3>Your Rights</h3>
      <p>
        You have the right to access, update or delete your personal information. You can do this by logging into your
        account settings.
      </p>

      <h3>Changes to This Policy</h3>
      <p>We reserve the right to modify this Privacy Policy at any time. Any changes will be updated on this page.</p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us at{' '}
        <a href="mailto:manager@svag.group">manager@svag.group</a>
      </p>

      <DocsFooterEn />
    </>
  )
}

const Hy = () => {
  return (
    <>
      <h1>Գաղտնիության քաղաքականություն</h1>
      <p>Վերջին թարմացում: 17.10.2023</p>
      <p>
        Սույն Գաղտնիության քաղաքականությունը կարգավորում է մեր կայքի օգտագործումը, որը տեղակայված է{' '}
        <a href="https://svag.group">https://svag.group</a> հասցեում ("Կայք"), որը կառավարվում է ԱՁ "Սերգեյ Դմիտրիև
        Իգորևիչ" կողմից, Հայաստանի անհատ ձեռնարկատեր, որն այսուհետ կոչվում է "SVAG", "մենք", "մեզ" կամ "մեր"։
      </p>
      <h3>Հավաքվող տեղեկատվություն</h3>
      <ul>
        <li>
          Անձնական տվյալներ: Մենք կարող ենք հավաքել անձնական տվյալներ, ինչպիսիք են անունը, էլեկտրոնային փոստի հասցեն և
          վճարային տվյալները, գրանցման կամ գնում կատարելու ժամանակ։
        </li>
        <li>Օգտագործման տվյալներ: Մենք կարող ենք հավաքել տվյալներ, թե ինչպես եք օգտագործում մեր կայքը։</li>
        <li>Cookie-ներ: Մենք օգտագործում ենք cookie-ներ՝ օգտագործողի փորձը բարելավելու համար։</li>
      </ul>

      <h3>Ինչպես ենք օգտագործում ձեր տեղեկատվությունը</h3>
      <p>
        Մենք օգտագործում ենք հավաքված տեղեկատվությունը տարբեր նպատակներով, ներառյալ, բայց ոչ սահմանափակված՝
        ծառայությունների տրամադրումը, ձեր հետ նույնականացումը և հաղորդակցումը, ինչպես նաև մեր ծառայությունների
        բարելավումը։
      </p>

      <h3>Անվտանգություն</h3>
      <p>
        Մենք լուրջ ենք վերաբերվում ձեր տվյալների անվտանգությանը և կիրառում ենք անհրաժեշտ անվտանգության միջոցառումներ
        դրանց պաշտպանության համար։
      </p>

      <h3>Երրորդ կողմերի ծառայություններ</h3>
      <p>
        Մենք կարող ենք ներգրավել երրորդ կողմերի ծառայությունները մեր ծառայությունների իրականացման համար, և նրանք կարող
        են մուտք ունենալ ձեր անձնական տեղեկատվությանը։
      </p>

      <h3>Ձեր իրավունքները</h3>
      <p>
        Դուք իրավունք ունեք մուտք գործելու, թարմացնելու կամ ջնջելու ձեր անձնական տվյալները։ Դուք կարող եք դա անել՝ մուտք
        գործելով ձեր հաշվի կարգավորումները։
      </p>

      <h3>Փոփոխություններ այս քաղաքականության մեջ</h3>
      <p>
        Մենք պահպանում ենք իրավունք՝ ցանկացած ժամանակ փոփոխություններ կատարելու սույն Գաղտնիության քաղաքականության մեջ։
        Ցանկացած փոփոխություն թարմացվելու է այս էջում։
      </p>

      <h3>Կապվեք մեզ հետ</h3>
      <p>
        Եթե դուք ունեք հարցեր սույն Գաղտնիության քաղաքականության վերաբերյալ, խնդրում ենք կապվել մեզ հետ հետևյալ հասցեով{' '}
        <a href="mailto:manager@svag.group">manager@svag.group</a>
      </p>
      <DocsFooterHy />
    </>
  )
}

export const PolicyPage = withPageWrapper({
  title: ({ lang }) =>
    lang === 'ru' ? 'Политика конфиденциальности' : lang === 'hy' ? 'Գաղտնիության քաղաքականություն' : 'Privacy Policy',
  langs: ['ru', 'en', 'hy'],
})(() => {
  const { lang } = useLang()
  return <RichText>{lang === 'ru' ? <Ru /> : lang === 'hy' ? <Hy /> : <En />}</RichText>
})
