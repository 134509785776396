import { RestorePassword } from '../../../components/auth/RestorePassword'
import { withPageWrapper } from '../../../lib/pageWrapper'

export const RestorePasswordPage = withPageWrapper({
  title: ({ t }) => t('restorePasswordPage.title'),
  redirectAuthorized: true,
  langs: ['ru', 'en', 'hy'],
})(() => {
  return <RestorePassword />
})
