import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { VideoAny } from '../../../../components/other/VideoAny'

export const GuideLessonVideo = ({
  lesson,
}: {
  lesson: Pick<
    TrpcRouterOutput['getStudentGuideLesson']['lesson'],
    'vkVideoId' | 'youtubeVideoId' | 'youtubeVideoEnId' | 'kinescopeVideoId' | 'videoWidth' | 'videoHeight' | 'title'
  >
}) => {
  return (
    <VideoAny
      withTopPadding
      vkId={lesson.vkVideoId}
      youtubeId={lesson.youtubeVideoId}
      youtubeEnId={lesson.youtubeVideoEnId}
      kinescopeId={lesson.kinescopeVideoId}
    />
  )
}
