import React from 'react'
import { useCurrency } from '../../../../../lib/currency'
import { useT } from '../../../../../lib/i18n'
import { Button, Buttons } from '../../../../ui/Button'
import { Card } from '../../../../ui/Card'
import { type EdItem } from '../../EdCard'

export const GroupCardDefault = ({
  setRef,
  groupInstance,
}: {
  setRef: React.RefCallback<any>
  groupInstance: Extract<EdItem, { type: 'group' }>
}) => {
  const { toMoneyWithCurrency } = useCurrency()
  const { t } = useT('groupCardDefault')
  const { t: tg } = useT('general')

  return (
    <Card
      setRef={setRef}
      title={`${groupInstance.title}`}
      hintColor={groupInstance.direction}
      hintIconName="group"
      hintText={t('hintPrefix') + ' / ' + tg(`directions.${groupInstance.direction}`)}
      content={groupInstance.desc}
      price={`${toMoneyWithCurrency({
        rub: groupInstance.priceRub,
        usd: groupInstance.priceUsd,
        amd: groupInstance.priceAmd,
      })} ${t('pricePostfix')}`}
      buttons={
        <Buttons stretch>
          <Button
            to={groupInstance.urlPath}
            color="brandAccentLight"
            eventElementId="openGroupButton"
            eventElementGroup="edButton"
          >
            {t('buttonText')}
          </Button>
        </Buttons>
      }
    />
  )
}
