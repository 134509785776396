import { IntroLayout } from '../../../../components/layouts/IntroLayout'
import { withPageWrapper } from '../../../../lib/pageWrapper'
import { SpecialistsPageContacts } from './SpecialistsPageContacts'
import { SpecialistsPageEd } from './SpecialistsPageEd'
import { SpecialistsPageIntro } from './SpecialistsPageIntro'
import { SpecialistsPageMembers } from './SpecialistsPageMembers'
import { SpecialistsPagePlan } from './SpecialistsPagePlan'
import { SpecialistsPageProjects } from './SpecialistsPageProjects'

export const SpecialistsPromoPage = withPageWrapper({
  langs: ['ru', 'en', 'hy'],
  showLoaderOnFetching: false,
})(() => {
  return (
    <IntroLayout>
      <SpecialistsPageIntro />
      <SpecialistsPagePlan />
      <SpecialistsPageMembers />
      <SpecialistsPageProjects />
      <SpecialistsPageEd />
      <SpecialistsPageContacts />
    </IntroLayout>
  )
})
