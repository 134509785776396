import cn from 'classnames'
import { useState } from 'react'
import css from './index.module.scss'
import { getVkVideoEmbedUrl, getVkVideoSize } from './utils'

export const VideoVk = ({
  id,
  width,
  height,
  className,
  eventElementId,
  eventElementGroup,
}: {
  id: string
  width?: number
  height?: number
  className?: string
  eventElementId?: string
  eventElementGroup?: string
}) => {
  const embedUrl = getVkVideoEmbedUrl({ id })
  const size = getVkVideoSize({ id, width, height })
  const [title] = useState(Math.random().toString())
  return (
    <div className={cn(css.videoOuterOuter, className)}>
      <div
        className={css.videoOuter}
        style={{
          paddingBottom: size.ratioPercentString,
        }}
      >
        <iframe
          className={css.video}
          src={embedUrl}
          width={width || size.width}
          height={height || size.height}
          title={title}
          allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
