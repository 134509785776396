import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { getContactLink } from '@svag/shared/src/getContactLink'
import format from 'date-fns/format'
import { openPurchaseProductModal } from '../../../../components/billing/PurchaseProduct/store'
import { PublicUserPreview } from '../../../../components/other/PublicUserPreview'
import { Button, Buttons } from '../../../../components/ui/Button'
import { LabelValueInfo } from '../../../../components/ui/LabelValueInfo'
import { PrettyText } from '../../../../components/ui/PrettyText'
import { Segment, Segments } from '../../../../components/ui/Segment'
import { useCurrency } from '../../../../lib/currency'
import { useT } from '../../../../lib/i18n'
import { trackRequestGroup } from '../../../../lib/mixpanel'
import css from './index.module.scss'

export const GroupPageStudent = ({
  groupInstance,
}: {
  groupInstance: TrpcRouterOutput['getGroupInstance']['groupInstance']
}) => {
  const { t } = useT('groupPageStudent')
  const { toMoneyWithCurrency } = useCurrency()
  return (
    <Segment title={t('info.title')}>
      <Segments>
        <Segment size="M" title={t('info.segmentTitle')}>
          <PrettyText text={groupInstance.desc} />
          {/* <div className={css.infoAndButtons}>
            <div className={css.info}>
              <LabelValueInfo
                size="m"
                items={[
                  {
                    label: 'О группе',
                    value: groupInstance.desc,
                  },
                ]}
              />
            </div>
          </div> */}
        </Segment>
        <Segment size="M" title={t('membership.title')}>
          <div className={css.infoAndButtons}>
            <div className={css.info}>
              <LabelValueInfo
                size="m"
                items={[
                  ...(groupInstance.isMeActiveStudent && groupInstance.myMembershipValidUntil
                    ? [
                        {
                          label: t('membership.paidUntil'),
                          value: format(new Date(groupInstance.myMembershipValidUntil), 'dd.MM.yyyy'),
                        },
                        {
                          label: t('membership.telegramGroup'),
                          value: (
                            <a href={groupInstance.telegram} target="_blank" rel="noreferrer">
                              {groupInstance.telegram}
                            </a>
                          ),
                        },
                      ]
                    : [
                        {
                          label: t('membership.status'),
                          value: t('membership.notActive'),
                        },
                      ]),
                  ...(groupInstance.membershipRenewableAt
                    ? [
                        {
                          label: t('membership.renewableAt'),
                          value: format(new Date(groupInstance.membershipRenewableAt), 'dd.MM.yyyy'),
                        },
                      ]
                    : []),
                  ...(groupInstance.startsAt
                    ? [
                        {
                          label: t('membership.startsAt'),
                          value: format(new Date(groupInstance.startsAt), 'dd.MM.yyyy'),
                        },
                      ]
                    : []),
                  {
                    label: t('membership.cost'),
                    value: toMoneyWithCurrency({
                      usd: groupInstance.priceUsd,
                      rub: groupInstance.priceRub,
                      amd: groupInstance.priceAmd,
                    }),
                  },
                ]}
              />
            </div>
            {!groupInstance.isMeInvalidated && !groupInstance.isMeValidated && (
              <div className={css.buttons}>
                <Buttons>
                  <Button
                    href={getContactLink(groupInstance.teacher)}
                    onClick={() => {
                      trackRequestGroup({
                        group: groupInstance,
                      })
                    }}
                  >
                    {t('membership.enrollButton')}
                  </Button>
                </Buttons>
              </div>
            )}
            {groupInstance.isStartable && (
              <div className={css.buttons}>
                <Buttons>
                  <Button
                    onClick={() => {
                      openPurchaseProductModal({
                        type: 'groupStart',
                        options: {
                          groupInstanceId: groupInstance.id,
                        },
                      })
                    }}
                  >
                    {t('membership.payMembership')}
                  </Button>
                </Buttons>
              </div>
            )}
            {groupInstance.isRenewable && (
              <div className={css.buttons}>
                <Buttons>
                  <Button
                    onClick={() => {
                      openPurchaseProductModal({
                        type: 'groupRenew',
                        options: {
                          groupInstanceId: groupInstance.id,
                        },
                      })
                    }}
                  >
                    {t('membership.renewMembership')}
                  </Button>
                </Buttons>
              </div>
            )}
          </div>
        </Segment>
        <Segment size="M" title={t('teacher.title')}>
          <PublicUserPreview size="m" publicUser={groupInstance.teacher} />
        </Segment>
      </Segments>
    </Segment>
  )
}
