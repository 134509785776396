import { EdCards } from '../../../components/education/cards/EdCard'
import { Contacts } from '../../../components/general/Contacts'
import { Members } from '../../../components/general/Members'
import { Projects } from '../../../components/general/Projects'
import { Publications } from '../../../components/general/Publications'
import { IntroLayout } from '../../../components/layouts/IntroLayout'
import { RichText } from '../../../components/ui/RichText'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getTeamRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const TeamPage = withPageWrapper({
  showLoaderOnFetching: false,
  useQuery: () => {
    const params = getTeamRoute.useParams()
    return trpc.getTeam.useQuery({
      teamSlug: params.teamSlug,
    })
  },
  setProps: ({ queryResult }) => {
    return {
      ...queryResult.data,
    }
  },
  title: ({ queryResult }) => queryResult.data.team.name,
  langs: ['ru', 'en', 'hy'],
})(({ projects, publications, specialists, team, offers }) => {
  const { t } = useT('teamPage')

  return (
    <IntroLayout>
      <div className={css.wrapper}>
        <div className={css.sections}>
          <Segment h={1} size="2XL" title={team.name}>
            <div className={css.avatarAndInfo}>
              <img src={team.avatar || ''} alt="" className={css.avatar} />
              <div className={css.info}>
                <div className={css.text}>
                  <p>{team.desc}</p>
                  {/* {!!specialist.contacts.length && (
                    <p>
                      <Contacts contacts={specialist.contacts} />
                    </p>
                  )} */}
                </div>
              </div>
            </div>
          </Segment>
          {!!team.contacts.length && (
            <Segment h={2} size="L" title={t('contacts.title')}>
              <div className={css.text}>
                <Contacts contacts={team.contacts} />
              </div>
            </Segment>
          )}
          {!!team.aboutHtml && (
            <Segment h={2} size="L" title={t('about.title')}>
              <RichText html={team.aboutHtml} />
            </Segment>
          )}
          {!!specialists.length && (
            <Segment h={2} size="L" title={t('members.title')}>
              <Members specialists={specialists} />
            </Segment>
          )}
          {!!projects.length && (
            <Segment h={2} size="L" title={t('projects.title')}>
              <Projects projects={projects} />
            </Segment>
          )}
          {!!publications.length && (
            <Segment h={2} size="L" title={t('publications.title')}>
              <Publications publications={publications} />
            </Segment>
          )}
          {!!offers.length && (
            <Segment h={2} size="L" title={t('offers.title')}>
              <EdCards
                edItems={offers}
                getColumnsCount={({ items, windowWidth }) =>
                  items.length < 2 ? 1 : items.length < 3 ? 2 : windowWidth > 1100 ? 3 : windowWidth > 900 ? 2 : 1
                }
              />
            </Segment>
          )}
        </div>
      </div>
    </IntroLayout>
  )
})
