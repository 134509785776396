import { pgr } from '../utils/pumpGetRoute'

export const getHomeRoute = pgr(() => '/')
export const getClientsPromoRoute = pgr(() => '/clients')
export const getSpecialistsPromoRoute = pgr(() => '/specialists')
export const getEdDevPromoRoute = pgr(() => '/education/dev-web')
export const getEdDevPromoNewbieRoute = pgr(() => '/education/dev-web/newbie')
export const getEdDevPromoJTMRoute = pgr(() => '/education/dev-web/jun-mid')
export const getEdDevPromoJTMShortRoute = pgr(() => '/education/dev-web/jun-mid-short')

const authRoutesPrefix = '/auth'
export const authRouteGroup = pgr(() => authRoutesPrefix)

export const getSignUpRoute = pgr(() => `${authRoutesPrefix}/sign-up`)
export const getSignInRoute = pgr(() => `${authRoutesPrefix}/sign-in`)
export const getRestorePasswordRoute = pgr(() => `${authRoutesPrefix}/restore-password`)
export const getResetPasswordRoute = pgr(
  { resetPasswordToken: true },
  ({ resetPasswordToken }) => `${authRoutesPrefix}/reset-password/${resetPasswordToken}`
)
export type ResetPasswordRouteParams = typeof getResetPasswordRoute.placeholders
export const getSignOutRoute = pgr(() => `/sign-out`)
export const getEmailConfirmationRoute = pgr(
  { emailConfirmationToken: true },
  ({ emailConfirmationToken }) => `${authRoutesPrefix}/confirm-email/${emailConfirmationToken}`
)
export type EmailConfirmationRouteParams = typeof getEmailConfirmationRoute.placeholders
export const getNewEmailConfirmationRoute = pgr(
  { newEmailConfirmationToken: true },
  ({ newEmailConfirmationToken }) => `${authRoutesPrefix}/confirm-new-email/${newEmailConfirmationToken}`
)
export type NewEmailConfirmationRouteParams = typeof getNewEmailConfirmationRoute.placeholders

const articleRoutesPrefix = '/article'
export const articleRouteGroup = pgr(() => articleRoutesPrefix)
export const getArticleRoute = pgr({ articleSlug: true }, ({ articleSlug }) => `${articleRoutesPrefix}/${articleSlug}`)
export type ArticleRouteParams = typeof getArticleRoute.placeholders

const accountRoutesPrefix = '/account'
export const accountRouteGroup = pgr(() => accountRoutesPrefix)

export const getEditProfileRoute = pgr(() => `${accountRoutesPrefix}/edit-profile`)
export const getDashboardRoute = pgr(() => `${accountRoutesPrefix}/dashboard`)
export const getEducationRoute = pgr(() => `${accountRoutesPrefix}/education`)
export const getGuidesRoute = pgr(() => `${accountRoutesPrefix}/education/guides`)

export const getConsultationsRoute = pgr(() => `${accountRoutesPrefix}/education/consultations`)
export const getNewConsultationRoute = pgr(() => `${accountRoutesPrefix}/education/consultations/new`)
export const getConsultationRoute = pgr(
  { consultationInstanceSerialNumber: true },
  ({ consultationInstanceSerialNumber }) =>
    `${accountRoutesPrefix}/education/consultations/${consultationInstanceSerialNumber}`
)
export type ConsultationRouteParams = typeof getConsultationRoute.placeholders

export const getGroupsRoute = pgr(() => `${accountRoutesPrefix}/education/groups`)
export const getGroupRoute = pgr(
  { groupInstanceSlug: true },
  ({ groupInstanceSlug }) => `${accountRoutesPrefix}/education/groups/${groupInstanceSlug}`
)
export type GroupRouteParams = typeof getConsultationRoute.placeholders
export const getNewGroupStudentRoute = pgr(
  { groupInstanceSlug: true },
  ({ groupInstanceSlug }) => `${accountRoutesPrefix}/education/groups/${groupInstanceSlug}/new-student`
)

export const getCommuinityRoute = pgr(() => `${accountRoutesPrefix}/commuinity`)

const guidesRoutesPrefix = '/guide'
export const guidesRouteGroup = pgr(() => guidesRoutesPrefix)
export const getGuideHomeRoute = pgr({ guideSlug: true }, ({ guideSlug }) => `${guidesRoutesPrefix}/${guideSlug}`)
export type GuideHomeRouteParams = typeof getGuideHomeRoute.placeholders

export const getGuideLessonRoute = pgr(
  { guideSlug: true, lessonSlug: true },
  ({ guideSlug, lessonSlug }) => `${guidesRoutesPrefix}/${guideSlug}/lesson/${lessonSlug}`
)
export type GuideLessonRouteParams = typeof getGuideLessonRoute.placeholders

export const getGuideLessonCommitsRoute = pgr(
  { guideSlug: true, lessonSlug: true },
  ({ guideSlug, lessonSlug }) => `/guide-public-commits/${guideSlug}/lesson/${lessonSlug}`
)
export type GuideLessonCommitsRouteParams = typeof getGuideLessonRoute.placeholders

export const getGuideLessonVideoRoute = pgr(
  { guideSlug: true, lessonSlug: true },
  ({ guideSlug, lessonSlug }) => `/guide-public-video/${guideSlug}/lesson/${lessonSlug}`
)
export type GuideLessonVideoRouteParams = typeof getGuideLessonVideoRoute.placeholders

export const getGuideSourceCodeRoute = pgr(
  { guideSlug: true },
  ({ guideSlug }) => `${guidesRoutesPrefix}/${guideSlug}/source-code`
)
export type GuideSourceCodeRouteParams = typeof getGuideSourceCodeRoute.placeholders

export const getGuidePlansRoute = pgr(
  { guideSlug: true },
  ({ guideSlug }) => `${guidesRoutesPrefix}/${guideSlug}/plans`
)
export type GuidePlansRouteParams = typeof getGuidePlansRoute.placeholders

export type GuideLayoutRouteParams =
  | GuideSourceCodeRouteParams
  | GuidePlansRouteParams
  | GuideLessonRouteParams
  | GuideHomeRouteParams

const billingReturnRoutesPrefix = '/billing-finish'
export const billingReturnRouteGroup = pgr(() => billingReturnRoutesPrefix)
export const getReturnPawRoute = pgr(
  { pawStatus: true },
  ({ pawStatus }) => `${billingReturnRoutesPrefix}/paw/${pawStatus}`
)
export type ReturnPawRouteParams = typeof getReturnPawRoute.placeholders

const docsRoutesPrefix = '/doc'
export const docsRouteGroup = pgr(() => docsRoutesPrefix)

export const getTermsRoute = pgr(() => `${docsRoutesPrefix}/terms`)
export const getPolicyRoute = pgr(() => `${docsRoutesPrefix}/policy`)

export const getUserRoute = pgr({ userSlug: true }, ({ userSlug }) => `/u/${userSlug}`)
export const getTeamRoute = pgr({ teamSlug: true }, ({ teamSlug }) => `/t/${teamSlug}`)
export const getProjectRoute = pgr({ projectSlug: true }, ({ projectSlug }) => `/p/${projectSlug}`)
