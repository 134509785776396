/* eslint-disable import/order */
import { useStore } from '@nanostores/react'
import { type Currency } from '@svag/shared/src/currency'
import { normalizePrices, toMoneyByCurrency } from '@svag/shared/src/money'
import Cookies from 'js-cookie'
import { atom } from 'nanostores'
import React, { useCallback, useEffect } from 'react'
import { Loader } from '../components/ui/Loader'
import { useClient, useConfig, useMe } from './ctx'
import { trpc } from './trpc'
import { trackUserData } from './mixpanel'

const getCurrencyByCookies = () => {
  return Cookies.get('currency-svag') === 'rub'
    ? 'rub'
    : Cookies.get('currency-svag') === 'usd'
    ? 'usd'
    : Cookies.get('currency-svag') === 'amd'
    ? 'amd'
    : null
}

const currencyStore = atom<{ currency: Currency }>({ currency: getCurrencyByCookies() || 'usd' })

export const getCurrenciesFromStore = () => {
  return currencyStore.get().currency
}

const setCurrencyToCookies = (currency: Currency) => {
  Cookies.set('currency-svag', currency)
}

export const toHumanCurrency = (currency: Currency) => {
  return {
    rub: '₽, RUB',
    usd: '$, USD',
    amd: '֏, AMD',
  }[currency]
}

export const toCurrencyIconName = (currency: Currency) => {
  return {
    rub: 'coinRub' as const,
    usd: 'coinUsd' as const,
    amd: 'coinAmd' as const,
  }[currency]
}

export const useCurrency = () => {
  const updateCurrency = trpc.updateCurrency.useMutation()
  const { currency } = useStore(currencyStore)
  const config = useConfig()
  const trpcUtils = trpc.useContext()
  const me = useMe()
  const setCurrency = useCallback(
    (currency: Currency) => {
      setCurrencyToCookies(currency)
      currencyStore.set({ currency })
      if (me) {
        void updateCurrency.mutateAsync({ currency }).then((result) => {
          trpcUtils.getMe.setData(undefined, (prevResult) => {
            if (!prevResult) {
              return prevResult
            }
            return result
          })
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!!me]
  )
  const toMoneyWithCurrency = useCallback(
    ({ rub, usd, amd, hideSymbol }: { rub: number; usd: number; amd: number; hideSymbol?: boolean }) => {
      return toMoneyByCurrency({ rub, usd, amd, currency, hideSymbol })
    },
    [currency]
  )
  const toMoneyWithAutoCurrency = useCallback(
    ({ usd, hideSymbol }: { usd: number; hideSymbol?: boolean }) => {
      return toMoneyByCurrency({ rub: config.rubInUsd * usd, amd: config.amdInUsd * usd, usd, currency, hideSymbol })
    },
    [currency, config.rubInUsd, config.amdInUsd]
  )
  const normalizePricesWithCurrency = useCallback(
    (text: string) => {
      return normalizePrices({
        text,
        currency,
        rubInUsd: config.rubInUsd,
        amdInUsd: config.amdInUsd,
      })
    },
    [currency, config.rubInUsd]
  )
  const humanCurrency = toHumanCurrency(currency)
  const currencyIconName = toCurrencyIconName(currency)
  return {
    currency,
    humanCurrency,
    currencyIconName,
    toMoneyWithCurrency,
    toMoneyWithAutoCurrency,
    normalizePricesWithCurrency,
    setCurrency,
  }
}

export const CurrencyNormalizer = ({ children }: { children: React.ReactNode }) => {
  const { currency, setCurrency } = useCurrency()
  const currencyByCookies = getCurrencyByCookies()
  const client = useClient()

  // Set currency if no in cookies by client country
  useEffect(() => {
    if (client && !currencyByCookies) {
      setCurrency(client.country === 'ru' ? 'rub' : client.country === 'am' ? 'amd' : 'usd')
    }
  }, [currency, client, setCurrency, currencyByCookies])

  // Track user data currency
  useEffect(() => {
    trackUserData({ currency })
  }, [currency])

  const isLoading = !currencyByCookies
  if (isLoading) {
    return <Loader type="page" />
  }

  return children
}
