import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { getContactLink } from '@svag/shared/src/getContactLink'
import { plural } from '@svag/shared/src/plural'
import { type ReactNode } from 'react'
import { type EdItem } from '../../../../../../components/education/cards/EdCard'
import { CurrencySwitchPrice } from '../../../../../../components/other/CurrencySwitch'
import { VideoAny } from '../../../../../../components/other/VideoAny'
import { Button } from '../../../../../../components/ui/Button'
import { Icon } from '../../../../../../components/ui/Icon'
import { useCurrency } from '../../../../../../lib/currency'
import { useT } from '../../../../../../lib/i18n'
import { trackRequestConsultation } from '../../../../../../lib/mixpanel'
import { getGuideHomeRoute } from '../../../../../../lib/routes'
import { EdDevPromoPagePlansShort } from '../EdDevPromoPagePlansShort'
import css from './index.module.scss'

export const EdDevPromoPageAbout = ({
  guide,
  consultation,
  group,
  title,
  hideButtons,
}: {
  guide: TrpcRouterOutput['getStudentGuideFull']['guide']
  consultation: Extract<EdItem, { type: 'consultation' }>
  group: Extract<EdItem, { type: 'group' }>
  title?: ReactNode
  hideButtons?: boolean
}) => {
  const { t: tg } = useT('general')
  const { t } = useT('edDevPromoPageAbout')
  const { toMoneyWithCurrency } = useCurrency()

  const content = [
    {
      title: t('learnByVideo.title'),
      text: t('learnByVideo.text', { lessons: plural(guide.lessonsCount, tg('lessons1', { returnObjects: true })) }),
      price: <EdDevPromoPagePlansShort guide={guide} />,
      button: !hideButtons && (
        <Button
          to={getGuideHomeRoute({ guideSlug: guide.slug })}
          eventElementGroup="edPromoStartButton"
          eventElementId="edPromoAboutStartButton"
        >
          {t('startFree')}
        </Button>
      ),
    },
    // {
    //   title: t('developYourStartup.title'),
    //   text: t('developYourStartup.text'),
    // },
    {
      title: t('askForHelp.title'),
      text: t('askForHelp.text'),
      price: (
        <>
          <i>{t('notIncluded')}</i>
          <br />
          <CurrencySwitchPrice>
            {toMoneyWithCurrency({
              usd: consultation.priceUsd,
              rub: consultation.priceRub,
              amd: consultation.priceAmd,
            })}
          </CurrencySwitchPrice>{' '}
          {t('perHour')}
          <br />
          {t('askForHelp.minimumTime')}
        </>
      ),
      button: !hideButtons && (
        <Button
          target="_bank"
          href={getContactLink(consultation.consultant)}
          eventElementId="edPromoAboutConsultationButton"
          onClick={() => {
            trackRequestConsultation({
              consultation,
            })
          }}
        >
          {t('contact')}
        </Button>
      ),
    },
    // {
    //   title: t('studyInGroup.title'),
    //   text: t('studyInGroup.text'),
    //   price: (
    //     <>
    //       <i>{t('notIncluded')}</i>
    //       <br />
    //       <CurrencySwitchPrice>
    //         {toMoneyWithCurrency({ usd: group.priceUsd, rub: group.priceRub })}
    //       </CurrencySwitchPrice>{' '}
    //       {t('perMonth')}
    //     </>
    //   ),
    //   button: !hideButtons && (
    //     <Button
    //       target="_bank"
    //       to={getGroupRoute({
    //         groupInstanceSlug: group.slug,
    //       })}
    //       eventElementId="edPromoAboutGroupButton"
    //     >
    //       {t('signup')}
    //     </Button>
    //   ),
    // },
    // {
    //   title: t('onlinePractice.title'),
    //   text: t('onlinePractice.text'),
    //   price: t('free'),
    //   button: !hideButtons && (
    //     <Button target="_bank" to={getCommuinityRoute()} eventElementId="edPromoAboutCommunityButton">
    //       {t('join')}
    //     </Button>
    //   ),
    // },
  ]

  return (
    <div className={css.about} id="about">
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{title || t('title')}</span>
        </h1>
        <div className={css.videoAndText}>
          <div className={css.videoPlace}>
            <VideoAny
              eventElementGroup="edPromoVideo"
              eventElementId="edPromoAboutVideo"
              kinescopeId="c251279f-9ce4-4ab0-95e4-e9451ffe586c"
              vkId="-227165132_456239017"
              youtubeId="DrS98CPYCgU"
              youtubeEnId="Xw7_39orqXs"
              width={560}
              height={364}
            />
            {/* <VideoYoutube
              eventElementGroup="edPromoVideo"
              eventElementId="edPromoAboutVideo"
              id="DrS98CPYCgU"
              width={560}
              height={364}
            /> */}
          </div>
          <div className={css.avatarAndText}>
            <img className={css.avatar} width="88" height="88" src="/images/team/s4.png" alt="" />
            <div className={css.text}>
              <h2 className={css.title}>{t('author.name')}</h2>
              <ul className={css.list}>
                <li className={css.listItem}>{t('author.point1')}</li>
                <li className={css.listItem}>{t('author.point2')}</li>
                <li className={css.listItem}>{t('author.point3')}</li>
              </ul>
              <div className={css.socials}>
                {guide.author.youtube && (
                  <a target="_blank" href={guide.author.youtube} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="youtubeRound" />
                  </a>
                )}
                {guide.author.linkedin && (
                  <a target="_blank" href={guide.author.linkedin} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="linkedinRound" />
                  </a>
                )}
                {guide.author.facebook && (
                  <a target="_blank" href={guide.author.facebook} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="facebookRound" />
                  </a>
                )}
                {guide.author.instagram && (
                  <a target="_blank" href={guide.author.instagram} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="instagramRound" />
                  </a>
                )}
                {guide.author.twitter && (
                  <a target="_blank" href={guide.author.twitter} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="xRound" />
                  </a>
                )}
                {guide.author.devto && (
                  <a target="_blank" href={guide.author.devto} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="devtoRound" />
                  </a>
                )}
                {guide.author.habr && (
                  <a target="_blank" href={guide.author.habr} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="habrRound" />
                  </a>
                )}
                {guide.author.vkontakte && (
                  <a target="_blank" href={guide.author.vkontakte} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="vkontakteRound" />
                  </a>
                )}
                {guide.author.telegram && (
                  <a target="_blank" href={guide.author.telegram} className={css.social} rel="noreferrer">
                    <Icon width={32} height={32} className={css.icon} name="telegramRound" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={css.items}>
          {content.map((item, index) => (
            <div className={css.itemOuter} key={index}>
              <div className={css.item}>
                <div className={css.top}>
                  <div className={css.title}>{item.title}</div>
                  <div className={css.text}>{item.text}</div>
                </div>
                <div className={css.bottom}>
                  {item.price && (
                    <div className={css.price}>
                      {/* <Icon name="wallet" className={css.icon} />  */}
                      {item.price}
                    </div>
                  )}
                  {item.button && <div className={css.buttons}>{item.button}</div>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
