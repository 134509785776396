import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { type Currency } from '@svag/shared/src/currency'
import { toMoneyByCurrency } from '@svag/shared/src/money'
import format from 'date-fns/format'
import React, { useCallback } from 'react'
import { LabelValueInfo } from '../../../components/ui/LabelValueInfo'
import { PrettyText } from '../../../components/ui/PrettyText'
import { useCurrency } from '../../../lib/currency'
import { tlt } from '../../../lib/i18n'

export type ConsultationPageInstanceInfoItemType = {
  key:
    | 'serialNumber'
    | 'status'
    | 'attendee'
    | 'consultant'
    | 'happendAt'
    | 'scheduledAt'
    | 'prepay'
    | 'afterpay'
    | 'price'
    | 'notes'
  label: React.ReactNode
  value: React.ReactNode
}
export type ConsultationPageInstanceInfoType = ConsultationPageInstanceInfoItemType[]

type ConsultationInstanceType =
  | TrpcRouterOutput['getConsultationInstancesForAttendee']['consultationInstances'][number]
  | TrpcRouterOutput['getConsultationInstancesForConsultant']['consultationInstances'][number]
  | TrpcRouterOutput['getConsultationInstance']['consultationInstance']

const getConsultationInstanceInfo = ({
  consultationInstance,
  pickKeys,
  currency,
}: {
  consultationInstance: ConsultationInstanceType
  pickKeys: Array<ConsultationPageInstanceInfoItemType['key']>
  currency: Currency
}): ConsultationPageInstanceInfoType => {
  const info: ConsultationPageInstanceInfoType = []
  info.push({
    key: 'serialNumber',
    label: tlt('consultationPageInstanceInfo.serialNumber.label'),
    value: consultationInstance.serialNumber,
  })
  info.push({
    key: 'status',
    label: tlt('consultationPageInstanceInfo.status.label'),
    value: tlt(`consultationPageInstanceInfo.status.values.${consultationInstance.status}`),
  })
  info.push({
    key: 'attendee',
    label: tlt('consultationPageInstanceInfo.attendee.label'),
    value: consultationInstance.attendee.name,
  })
  info.push({
    key: 'consultant',
    label: tlt('consultationPageInstanceInfo.consultant.label'),
    value: consultationInstance.consultant.name,
  })
  if (consultationInstance.happendAt) {
    const value = format(consultationInstance.happendAt, 'dd.MM.yyyy HH:mm')
    info.push({
      key: 'happendAt',
      label: tlt('consultationPageInstanceInfo.happendAt.label'),
      value,
    })
  } else if (consultationInstance.scheduledAt) {
    const value = format(consultationInstance.scheduledAt, 'dd.MM.yyyy HH:mm')
    info.push({
      key: 'scheduledAt',
      label: tlt('consultationPageInstanceInfo.scheduledAt.label'),
      value,
    })
  }
  if (consultationInstance.prepayRequiredAmountUsd) {
    const isPaid = !!consultationInstance.prepayPaidAt
    const deadlineString = consultationInstance.prepayDeadlineAt
      ? format(new Date(consultationInstance.prepayDeadlineAt), 'dd.MM.yyyy HH:mm')
      : ''
    const moneyString = toMoneyByCurrency({
      usd: consultationInstance.prepayRequiredAmountUsd,
      rub: consultationInstance.prepayRequiredAmountRub,
      amd: consultationInstance.prepayRequiredAmountAmd,
      currency,
    })
    if (isPaid) {
      info.push({
        key: 'prepay',
        label: tlt('consultationPageInstanceInfo.prepay.label'),
        value: tlt('consultationPageInstanceInfo.prepay.paid', { moneyString }),
      })
    } else if (deadlineString) {
      info.push({
        key: 'prepay',
        label: tlt('consultationPageInstanceInfo.prepay.label'),
        value: tlt('consultationPageInstanceInfo.prepay.waitingWithDeadline', { moneyString, deadlineString }),
      })
    } else {
      info.push({
        key: 'prepay',
        label: tlt('consultationPageInstanceInfo.prepay.label'),
        value: tlt('consultationPageInstanceInfo.prepay.waiting', { moneyString }),
      })
    }
  }
  if (consultationInstance.afterpayRequiredAmountUsd) {
    const isPaid = !!consultationInstance.afterpayPaidAt
    const value =
      (isPaid
        ? tlt('consultationPageInstanceInfo.afterpay.paid')
        : tlt('consultationPageInstanceInfo.afterpay.waiting')) +
      ' ' +
      toMoneyByCurrency({
        usd: consultationInstance.afterpayRequiredAmountUsd,
        rub: consultationInstance.afterpayRequiredAmountRub,
        amd: consultationInstance.afterpayRequiredAmountAmd,
        currency,
      })
    info.push({
      key: 'afterpay',
      label: tlt('consultationPageInstanceInfo.afterpay.label'),
      value,
    })
  }
  if (consultationInstance.priceUsd && consultationInstance.priceRub && consultationInstance.priceAmd) {
    info.push({
      key: 'price',
      label: tlt('consultationPageInstanceInfo.price.label'),
      value: (
        <>
          {toMoneyByCurrency({
            usd: consultationInstance.priceUsd,
            rub: consultationInstance.priceRub,
            amd: consultationInstance.priceAmd,
            currency,
          })}{' '}
          {tlt('consultationPageInstanceInfo.price.perHour')}
        </>
      ),
    })
  }
  if (consultationInstance.notes) {
    info.push({
      key: 'notes',
      label: tlt('consultationPageInstanceInfo.notes.label'),
      value: <PrettyText text={consultationInstance.notes} />,
    })
  }
  return info.filter((item) => pickKeys.includes(item.key))
}

export const useGetConsultationInstanceInfoWithCurrency = () => {
  const { currency } = useCurrency()
  const getConsultationInstanceInfoWithCurrency = useCallback(
    (props: {
      consultationInstance: ConsultationInstanceType
      pickKeys: Array<ConsultationPageInstanceInfoItemType['key']>
    }) => getConsultationInstanceInfo({ ...props, currency }),
    [currency]
  )
  return { getConsultationInstanceInfoWithCurrency }
}

export const ConsultationPageInstanceInfo = ({
  info,
  size,
}: {
  info: ConsultationPageInstanceInfoType
  size: 's' | 'm'
}) => {
  return <LabelValueInfo items={info} size={size} />
}
