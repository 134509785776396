import type { TrpcRouterOutput } from '@svag/backend/src/router'
import { useParams } from 'react-router-dom'
import { Alert } from '../../../components/ui/Alert'
import { Segment } from '../../../components/ui/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type GuideLessonCommitsRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { GuideLessonCommits } from '../GuideLessonPage/GuideLessonCommits'
import css from './index.module.scss'

const LessonCommits = ({ lesson }: { lesson: TrpcRouterOutput['getGuideLessonCommits']['lesson'] }) => {
  return (
    <Segment title={lesson.title}>
      <div className={css.lesson}>
        {lesson.commits?.length ? (
          <div className={css.commitsPlace}>
            <GuideLessonCommits guideSlug={lesson.guideSlug} commits={lesson.commits} />
          </div>
        ) : (
          <Alert color="brown">У этого урока нет исходного кода</Alert>
        )}
      </div>
    </Segment>
  )
}

export const GuideLessonCommitsPage = withPageWrapper({
  title: ({ queriesResults }) => queriesResults[0].data?.lesson?.title,
  useQueries: () => {
    const { guideSlug, lessonSlug } = useParams() as GuideLessonCommitsRouteParams
    return trpc.useQueries((t) => [
      t.getGuideLessonCommits({
        guideSlug,
        lessonSlug,
      }),
    ])
  },
  showLoaderOnFetching: false,
  setProps: ({ queriesResults }) => ({
    lesson: queriesResults[0].data.lesson,
  }),
  langs: ['ru', 'en', 'hy'],
})(({ lesson }) => {
  return <LessonCommits lesson={lesson} />
})
