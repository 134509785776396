import { useCurrency } from '../../../../lib/currency'
import { useT } from '../../../../lib/i18n'
import { trackRequestConsultation } from '../../../../lib/mixpanel'
import { Button, Buttons } from '../../../ui/Button'
import { Card, type CardProps } from '../../../ui/Card'
import { type EdItem } from '../EdCard'

export const ConsultationCard = (
  consultation: Extract<EdItem, { type: 'consultation' }> & Pick<CardProps, 'setRef'>
) => {
  const { t } = useT('educationConsultationCard')
  const { t: tg } = useT('general')
  const { direction, title, desc, setRef, priceUsd, priceRub, priceAmd, urlPath } = consultation
  const { toMoneyWithCurrency } = useCurrency()
  return (
    <Card
      setRef={setRef}
      hintColor={direction}
      hintIconName="consultation"
      hintText={t('constultation') + ' / ' + tg(`directions.${direction}`)}
      title={title}
      content={desc}
      price={`${toMoneyWithCurrency({ rub: priceRub, usd: priceUsd, amd: priceAmd })} ${t('perHour')}`}
      buttons={
        <Buttons stretch>
          <Button
            color="brandAccentLight"
            href={urlPath}
            target="_blank"
            onClick={() => {
              trackRequestConsultation({
                consultation,
              })
            }}
            eventElementGroup="edButton"
            eventElementId="requestConsultationButton"
          >
            {t('buttonText')}
          </Button>
        </Buttons>
      }
    />
  )
}
