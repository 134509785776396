import { useLang, useT } from '../../../../../../lib/i18n'
import css from './index.module.scss'

export const EdDevPromoPageReviews = () => {
  const { lang } = useLang()
  const { t } = useT('edDevPromoPageReviews')

  if (lang === 'en') {
    return null
  }

  return (
    <div className={css.reviews} id="reviews">
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('sectionTitle')}</span>
        </h1>
      </div>
      <div className={css.picturePlace}>
        <img className={css.picture} src="/images/ed/dev/reviews.png" alt="" />
      </div>
    </div>
  )
}
