import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert } from '../../../components/ui/Alert'
import { Loader } from '../../../components/ui/Loader'
import { Segment } from '../../../components/ui/Segment'
import { useT } from '../../../lib/i18n'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type GuideHomeRouteParams, getGuideLessonRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const GuideHomePage = withPageWrapper({
  title: ({ t }) => t('guideHomePage.title'),
  eventsContext: ({ queryResult }) => ({
    guide: queryResult.data?.guide,
  }),
  useQuery: () => {
    const { guideSlug } = useParams() as GuideHomeRouteParams
    return trpc.getStudentGuideFull.useQuery(
      {
        guideSlug,
      },
      {
        staleTime: 3000,
      }
    )
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    guide: queryResult.data.guide,
  }),
  langs: ['ru', 'en', 'hy'],
})(({ guide }) => {
  const { t } = useT('guideHomePage')
  const navigate = useNavigate()
  useEffect(() => {
    if (!guide.currentGuideLesson) {
      return
    }
    const lessonSlug = guide.currentGuideLesson.slug
    const guideSlug = guide.slug
    navigate(getGuideLessonRoute({ guideSlug, lessonSlug }), {
      replace: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guide.currentGuideLesson, guide.slug])

  if (guide.isReady && guide.currentGuideLesson) {
    return <Loader type="section" />
  }

  if (guide.isReady && !guide.currentGuideLesson) {
    return (
      <Segment title={t('errors.somethingWentWrong')}>
        <Alert color="red">{t('errors.cannotDetermineLesson')}</Alert>
      </Segment>
    )
  }

  return (
    <Segment title={t('development.title')}>
      <Alert color="brown">{t('development.estimatedLaunchDate', { date: guide.willBeReadyAt })}</Alert>
    </Segment>
  )
})
