import { useEffect } from 'react'
import { Alert } from '../../../components/ui/Alert'
import { Loader } from '../../../components/ui/Loader'
import { Segment } from '../../../components/ui/Segment'
import { useForm } from '../../../lib/form'
import { useT } from '../../../lib/i18n'
import { trackEmailVerificationFail, trackEmailVerificationSuccess } from '../../../lib/mixpanel'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEmailConfirmationRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const ConfirmEmailPage = withPageWrapper({
  title: ({ t }) => t('confirmEmailPage.title'),
  langs: ['ru', 'en', 'hy'],
})(() => {
  const { t } = useT('confirmEmailPage')
  const { emailConfirmationToken } = getEmailConfirmationRoute.useParams()
  const confirmEmail = trpc.confirmEmail.useMutation()
  const trpcUtils = trpc.useContext()
  const { formik, alertProps } = useForm({
    onSubmit: async () => {
      try {
        await confirmEmail.mutateAsync({ emailConfirmationToken })
        trackEmailVerificationSuccess()
      } catch (error) {
        trackEmailVerificationFail()
        throw error
      }
      trpcUtils.getMe.setData(undefined, (prevData) => {
        return !prevData
          ? prevData
          : {
              ...prevData,
              me: !prevData.me
                ? null
                : {
                    ...prevData.me,
                    emailConfirmed: true,
                  },
            }
      })
    },
    successMessage: t('successMessage'),
    successMessageDuration: false,
    resetOnSuccess: false,
  })
  useEffect(() => {
    formik.handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!formik.submitCount || formik.isSubmitting) {
    return <Loader type="page" />
  }
  return (
    <Segment title={t('title')}>
      <Alert {...alertProps} />
    </Segment>
  )
})
