import { useParams } from 'react-router-dom'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { type GuideLessonVideoRouteParams } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import { GuideLessonVideo } from '../GuideLessonPage/GuideLessonVideo'

export const GuideLessonVideoPage = withPageWrapper({
  title: ({ queriesResults }) => queriesResults[0].data?.lesson?.title,
  useQueries: () => {
    const { guideSlug, lessonSlug } = useParams() as GuideLessonVideoRouteParams
    return trpc.useQueries((t) => [
      t.getGuideLessonVideo({
        guideSlug,
        lessonSlug,
      }),
    ])
  },
  showLoaderOnFetching: false,
  setProps: ({ queriesResults }) => ({
    lesson: queriesResults[0].data.lesson,
  }),
  langs: ['ru', 'en', 'hy'],
})(({ lesson }) => {
  return <GuideLessonVideo lesson={lesson} />
})
